
  import { ref, onMounted } from 'vue'
  import pageTitle from '@/components/pageTitle.vue';
  import { ElMessage,ElMessageBox } from 'element-plus';
  import { getPersonList, getAreaList, getStationList,setPersonArea,syncCsData } from '@/axios/moveTV';
  interface tab {
    name: string,
    id: number,
    user_id?: number|string
  }
  export default{
    setup() {
      let names = ref('');
      let jobs = ref(); //职务
      let areas = ref(); //区域
      let tableData = ref<tab[]>([]);
      let areaDialog = ref(false);
      let checkPeo = ref<tab[]>([]);
      let checkArea = ref([])
      let areaList = ref<tab[]>([])
      let stationList = ref<tab[]>([])

      onMounted(()=> {
        getbase();
        
      })
      function getbase(){
        let aci = JSON.parse(sessionStorage.getItem('accountInfo') || '')
        getAreaList({
          company_id: aci.company_list[sessionStorage.getItem('checkCompany') || 0].company_id
        }).then((data:any) => {
          console.log(data)
          areaList.value = data;
          // areas.value = data[0].id;

          return getStationList({
            company_id: aci.company_list[sessionStorage.getItem('checkCompany') || 0].company_id
          })
        }).then((data:any) => {
          stationList.value = data;
          // jobs.value = data[0].id;

          getPersonLists();
        }).catch((err:any) => {})
      }
      //人员列表
      function getPersonLists(){
        let aci = JSON.parse(sessionStorage.getItem('accountInfo') || '')
        getPersonList({
          company_id: aci.company_list[sessionStorage.getItem('checkCompany') || 0].company_id,
          area_id: areas.value,
          station_id: jobs.value,
          name: names.value,
        }).then((data:any) => {
          tableData.value = data;
        }).catch((err:any)=>{
        
        })
      }
      //表格复选框
      function selectionChange(val:tab[]){
        console.log(val)
        checkPeo.value = val
      }
      //设置服务区域
      function showArea(){
        console.log(checkPeo.value)
        if(checkPeo.value.length === 0){
          ElMessage('请选择服务人员');
          return false;
        }
        areaDialog.value = true;
      }

      //人员同步
      function syncs(){
        
        ElMessageBox.confirm('请在云管上完成服务人员配置').then(() => {
          syncCsData().then((data:any) => {
            console.log(data)
            ElMessage('同步完成');
            getbase();
          }).catch((err:any) => {
            console.log(err)
          })
        }).catch(() => {})

      }

      //弹窗取消
      function closeDia(){
        areaDialog.value = false;
        checkArea.value = [];
      }
      //弹窗确认
      function formClick(){
        if(checkPeo.value.length == 0){
          ElMessage('请选择服务区域');
          return false;
        }
        let aci = JSON.parse(sessionStorage.getItem('accountInfo') || '');
        let users = '';
        checkPeo.value.map(item => {
          users = users + item.user_id + ','
        })
        users = users.substring(0,users.length-1);
        setPersonArea({
          company_id: aci.company_list[sessionStorage.getItem('checkCompany') || 0].company_id,
          user_ids: users,
          area_ids: checkArea.value.join(','),
        }).then((data:any) => {
          ElMessage('区域设置成功');
          getPersonLists();
          closeDia();
        }).catch((err:any) => {})
      }
      return { 
        names,
        jobs,
        tableData,
        areaDialog,
        checkPeo,
        checkArea,
        areas,
        areaList,
        stationList,
        selectionChange,
        showArea,
        syncs,
        getPersonLists,
        closeDia,
        formClick
      }
    },
    components: {
      pageTitle
    }
  }
