import { creates } from './index';
import { SRVUrl } from '@/config/index';

//获取人员列表
export function getPersonList(params: object){
  return creates({
    url: SRVUrl + '/cs/person/getPersonList',
    method: 'GET',
    params: params
  })
}

//获取区域列表
export function getAreaList(params: object){
  return creates({
    url: SRVUrl + '/cs/person/getAreaList',
    method: 'GET',
    params: params
  })
}

//获取岗位列表
export function getStationList(params: object){
  return creates({
    url: SRVUrl + '/cs/person/getStationList',
    method: 'GET',
    params: params
  })
}

//同步财升员工、区域、职位
export function syncCsData(){
  return creates({
    url: SRVUrl + '/cs/person/csData',
    method: 'GET'
  })
}

//设置员工区域信息
export function setPersonArea(data: object){
  return creates({
    url: SRVUrl + '/cs/person/setPersonArea',
    method: 'POST',
    data: data
  })
}

//获取门店被动任务配置列表
export function getStoreTaskList(params: object){
  return creates({
    url: SRVUrl + '/cs/pasv/getStoreTaskList',
    method: 'GET',
    params: params
  })
}

//保存门店被动任务配置
export function saveStoreTask(data: object){
  return creates({
    url: SRVUrl + '/cs/pasv/saveStoreTask',
    method: 'POST',
    data: data
  })
}